import { VsmAppConfigObject } from '@/types/VsmAppConfigObject';

export default function findStyle(
    appConfig: VsmAppConfigObject,
    codeOrId?: string,
    type?: string
): any {
    if ((!codeOrId || !type) && appConfig.properties?.defaultStyle) {
        [codeOrId, type] = appConfig.properties.defaultStyle.split(':');
    }

    const target =
        appConfig.styles.find(
            ({ id, code }: any) => id === codeOrId || code === codeOrId
        ) ?? appConfig.styles[0];

    return (
        target.config.find((style: any) => style.type === type) ||
        target.config[0]
    );
}
