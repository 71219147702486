import {
    MapControlDock,
    NavigationControl,
    ReactButtonControl,
    ScaleBarControl
} from '@vsm/react-vsm';
import Vsm from '@vsm/vsm';
import { TiRefresh as ReloadIcon } from 'react-icons/all';

const { ControlDockNames } = Vsm.Map;

const MapControls = () => (
    <>
        <MapControlDock name={ControlDockNames.BottomLeft}>
            <ScaleBarControl />
        </MapControlDock>

        <MapControlDock name={ControlDockNames.BottomRight}>
            <ReactButtonControl onClick={() => window.location.reload()}>
                <ReloadIcon style={{ width: '85%', height: '85%' }} />
            </ReactButtonControl>

            <NavigationControl />
        </MapControlDock>
    </>
);

export default MapControls;
