import { DEFAULT_RESPONSE_TYPE } from '@/apis/constants';
import properties from '@/properties';
import { MapData } from '@/types/MapData';
import axios, { AxiosInstance } from 'axios';

const URL_PREFIX = `${properties.serverApi.urlPrefix}/configs`;
const REQUEST_CONFIG = {
    timeout: properties.serverApi.defaultTimeOut,
    responseType: DEFAULT_RESPONSE_TYPE
};

export async function getMap(
    mapId: string,
    axiosInstance: AxiosInstance = axios
): Promise<MapData> {
    const { data } = await axiosInstance.get(
        `${URL_PREFIX}/id/${mapId}`,
        REQUEST_CONFIG
    );
    const { body, position, ...otherData } = data;

    return {
        ...otherData,
        config: JSON.parse(body),
        position: position && JSON.parse(position)
    };
}
