import { Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MapControlDock, ReactBaseControl } from '@vsm/react-vsm';
import Vsm, { EnlargeScaleFactor } from '@vsm/vsm';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

const { ControlDockNames } = Vsm.Map;

const useStyles = makeStyles()(({ spacing, palette }: Theme) => ({
    root: {},
    buttonGroup: {
        backgroundColor: '#fafafa'
    },
    button: {
        textTransform: 'none',
        fontWeight: 600
    }
}));

type Props = {
    value?: EnlargeScaleFactor;
    onChange: (scaleFactor?: EnlargeScaleFactor) => any;
};

const MapEnlargeScaleFactor = (props: Props) => {
    const { classes } = useStyles();
    const { value = 2, onChange } = props;

    return (
        <>
            <MapControlDock name={ControlDockNames.TopRight}>
                <ReactBaseControl defaultInteractive>
                    <div className={classes.root}>
                        <ToggleButtonGroup
                            className={classes.buttonGroup}
                            exclusive
                            orientation='horizontal'
                            value={value}
                            onChange={(_, newValue) => onChange(newValue)}
                        >
                            <ToggleButton
                                className={clsx('vsm-button', classes.button)}
                                value={1}
                            >
                                1x
                            </ToggleButton>
                            <ToggleButton
                                className={clsx('vsm-button', classes.button)}
                                value={2}
                            >
                                2x
                            </ToggleButton>
                            <ToggleButton
                                className={clsx('vsm-button', classes.button)}
                                value={4}
                            >
                                4x
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </ReactBaseControl>
            </MapControlDock>
        </>
    );
};

export default MapEnlargeScaleFactor;
