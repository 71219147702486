import formatStyleKeyString from '@/utils/vsm/formatStyleKeyString';

export default function removeInvisibleStyle(styleConfig: any) {
    const styles = styleConfig.styles;
    const metadata = styleConfig.metadata;

    if (!metadata) {
        return;
    }

    for (const [layerId, layerStyles] of Object.entries(styles) as Array<
        [string, any]
    >) {
        if (!isVisible(metadata[formatStyleKeyString(layerId)])) {
            delete styles[layerId];
            continue;
        }

        for (const [stackId, stackStyles] of Object.entries(
            layerStyles
        ) as Array<[string, Array<any>]>) {
            if (!isVisible(metadata[formatStyleKeyString(layerId, stackId)])) {
                delete layerStyles[stackId];
                continue;
            }

            layerStyles[stackId] = stackStyles.filter(stackStyle =>
                isVisible(
                    metadata[
                        formatStyleKeyString(layerId, stackId, stackStyle.id)
                    ]
                )
            );
        }
    }
}

function isVisible(metadata: any) {
    return metadata && 'visible' in metadata ? metadata.visible : true;
}
