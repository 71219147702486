import { Popover, Switch, Theme, Typography } from '@mui/material';
import {
    MapContextProps,
    MapControlDock,
    MapEventListener,
    ReactButtonControl,
    withMapContext
} from '@vsm/react-vsm';
import Vsm from '@vsm/vsm';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { MdBuild as ButtonIcon } from 'react-icons/all';
import { makeStyles } from 'tss-react/mui';

const { ControlDockNames, AdditionalFuncNames } = Vsm.Map;

const useStyles = makeStyles()(({ spacing, palette }: Theme) => ({
    root: {},
    container: {
        padding: spacing(1)
    },
    title: {
        marginBottom: spacing(1)
    },
    contents: {
        padding: spacing(0.5)
    },
    box: {
        padding: spacing(0.5),
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: palette.text.disabled,
        borderRadius: spacing(0.5)
    },
    switch: {
        padding: spacing(0.25),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

export type DebugOptions = {
    tileDebugInfo: boolean;
    collisionDetection: boolean;
    poiVertex: boolean;
    network: boolean;
    forceBuildingInvisible: boolean;
};

export const DEFAULT_DEBUG_OPTIONS = {
    tileDebugInfo: false,
    collisionDetection: false,
    poiVertex: false,
    network: false,
    forceBuildingInvisible: false
};

type Props = {
    options: DebugOptions;
    onChange: (newOptions: DebugOptions, map: Vsm.Map) => any;
} & MapContextProps;

const DebugControl = (props: Props) => {
    const { classes } = useStyles();
    const { options, onChange, map } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [styleLoadedCount, setStyleLoadedCount] = useState(0);

    useEffect(() => {
        if (!map) {
            return;
        }

        for (const [key, checked] of Object.entries(options)) {
            const funcName = toAdditionFuncName(key as keyof DebugOptions);

            if (funcName) {
                if (checked) {
                    map.enable(funcName);
                } else {
                    map.disable(funcName);
                }
            } else {
                switch (key) {
                    case 'forceBuildingInvisible':
                        Vsm.Extensions.TmapUtils.setBuildingVisibility(
                            map,
                            !checked
                        );
                        break;
                }
            }
        }
    }, [map, styleLoadedCount, options]);

    const handleOptionChange = (key: keyof DebugOptions, value: any) =>
        map &&
        onChange(
            produce(options, draft => {
                draft[key] = value;
            }),
            map
        );

    return (
        <>
            <MapControlDock name={ControlDockNames.TopRight}>
                <ReactButtonControl
                    className={classes.root}
                    onClick={event => setAnchorEl(event.currentTarget)}
                >
                    <div>
                        <ButtonIcon style={{ width: '60%', height: '60%' }} />
                    </div>
                </ReactButtonControl>
            </MapControlDock>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <div className={classes.container}>
                    <div className={classes.title}>
                        <Typography variant='subtitle2'>
                            Debug Options
                        </Typography>
                    </div>
                    <div className={classes.contents}>
                        <div className={classes.box}>
                            <div className={classes.switch}>
                                <Typography variant='body2'>
                                    Tile Debug Info
                                </Typography>
                                <Switch
                                    size='small'
                                    checked={options.tileDebugInfo}
                                    onChange={(_, checked) =>
                                        handleOptionChange(
                                            'tileDebugInfo',
                                            checked
                                        )
                                    }
                                />
                            </div>

                            <div className={classes.switch}>
                                <Typography variant='body2'>
                                    Collision Detection
                                </Typography>
                                <Switch
                                    size='small'
                                    checked={options.collisionDetection}
                                    onChange={(_, checked) =>
                                        handleOptionChange(
                                            'collisionDetection',
                                            checked
                                        )
                                    }
                                />
                            </div>

                            <div className={classes.switch}>
                                <Typography variant='body2'>
                                    Poi Vertex
                                </Typography>
                                <Switch
                                    size='small'
                                    checked={options.poiVertex}
                                    onChange={(_, checked) =>
                                        handleOptionChange('poiVertex', checked)
                                    }
                                />
                            </div>

                            <div className={classes.switch}>
                                <Typography variant='body2'>Network</Typography>
                                <Switch
                                    size='small'
                                    checked={options.network}
                                    onChange={(_, checked) =>
                                        handleOptionChange('network', checked)
                                    }
                                />
                            </div>

                            <div className={classes.switch}>
                                <Typography variant='body2'>
                                    Force Building Invisible
                                </Typography>
                                <Switch
                                    size='small'
                                    checked={options.forceBuildingInvisible}
                                    onChange={(_, checked) =>
                                        handleOptionChange(
                                            'forceBuildingInvisible',
                                            checked
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>

            <MapEventListener
                name={Vsm.Map.EventNames.StyleLoad}
                listener={() => setStyleLoadedCount(styleLoadedCount + 1)}
            />
        </>
    );
};

export default withMapContext(DebugControl);

function toAdditionFuncName(
    key: keyof DebugOptions
): Vsm.MapAdditionalFuncName | null {
    switch (key) {
        case 'tileDebugInfo':
            return AdditionalFuncNames.ShowTileDebugInfo;
        case 'collisionDetection':
            return AdditionalFuncNames.ShowCollisionDetection;
        case 'poiVertex':
            return AdditionalFuncNames.ShowPoiVertex;
        case 'network':
            return AdditionalFuncNames.ShowNetwork;
        default:
            return null;
    }
}
