import * as profiles from '@/properties/profiles';

export * from '@/properties/profiles';

function selectByProfile() {
    const profile = process.env.REACT_APP_PROFILE;

    if (!profile) {
        return profiles.local;
    }

    switch (profile.toLowerCase()) {
        case 'development':
            return profiles.development;
        case 'production':
            return profiles.production;
        case 'local':
        default:
            return profiles.local;
    }
}

export default selectByProfile();
