import { Theme, Typography } from '@mui/material';
import {
    MapControlDock,
    MapEventListener,
    ReactBaseControl
} from '@vsm/react-vsm';
import Vsm from '@vsm/vsm';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const { EventNames, ControlDockNames } = Vsm.Map;

const useStyles = makeStyles()(({ spacing }: Theme) => ({
    root: {
        padding: spacing(1),
        fontSize: '25pt',
        fontWeight: 'bold',
        textShadow:
            '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'
    },
    hidden: {
        visibility: 'hidden'
    }
}));

const MapLevel = () => {
    const { classes } = useStyles();
    const [level, setLevel] = useState(-1);
    const [hidden, setHidden] = useState(true);
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
        null
    );

    const zoomHandler = useCallback((event: Vsm.Event) => {
        const { target: map } = event as Vsm.MapEvent;
        setLevel(Math.trunc(map.getTransform().getZoom()));
    }, []);

    const preventHidingLevel = useCallback(() => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
    }, [timer, setTimer]);

    const zoomStartHandler = useCallback(() => {
        preventHidingLevel();
        setHidden(false);
    }, [preventHidingLevel]);

    const zoomEndHandler = useCallback(() => {
        preventHidingLevel();

        const newTimer = setTimeout(() => {
            setHidden(true);
            setTimer(null);
        }, 1000);

        setTimer(newTimer);
    }, [preventHidingLevel]);

    return (
        <>
            <MapControlDock name={ControlDockNames.BottomLeft}>
                <ReactBaseControl>
                    <div
                        className={clsx(classes.root, {
                            [classes.hidden]: hidden
                        })}
                    >
                        <Typography variant='inherit'>{level}</Typography>
                    </div>
                </ReactBaseControl>
            </MapControlDock>

            <MapEventListener
                name={EventNames.ConfigLoad}
                listener={zoomHandler}
            />
            <MapEventListener name={EventNames.Zoom} listener={zoomHandler} />
            <MapEventListener
                name={EventNames.ZoomStart}
                listener={zoomStartHandler}
            />
            <MapEventListener
                name={EventNames.ZoomEnd}
                listener={zoomEndHandler}
            />
        </>
    );
};

export default MapLevel;
