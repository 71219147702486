import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Theme,
    Typography
} from '@mui/material';
import {
    ActivatedMapStyle,
    MapControlDock,
    MapEventListener,
    ReactBaseControl
} from '@vsm/react-vsm';
import Vsm from '@vsm/vsm';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import {
    FaAngleDoubleLeft as CloseIcon,
    FaAngleDoubleRight as OpenIcon
} from 'react-icons/all';
import { makeStyles } from 'tss-react/mui';
import { findStyle } from '@/utils/vsm';

const { EventNames, ControlDockNames } = Vsm.Map;

const useStyles = makeStyles()(({ spacing, palette }: Theme) => ({
    root: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
    },
    top: {
        'display': 'flex',
        'alignItems': 'center',
        '&>*:not(:last-child)': {
            marginRight: spacing(1)
        }
    },
    bottom: {
        padding: `0 ${spacing(1)}`
    },
    button: {
        fontSize: '1.2rem'
    }
}));

function toString({ id, type }: ActivatedMapStyle) {
    return `${id}.${type}`;
}

type Props = {
    value?: ActivatedMapStyle;
    onChange: (activatedMapStyle: ActivatedMapStyle) => any;
};

const MapStyleBox = (props: Props) => {
    const { classes } = useStyles();
    const { value, onChange } = props;
    const [opened, setOpened] = useState(false);
    const [availableStyles, setAvailableStyles] = useState<
        Array<ActivatedMapStyle>
    >([]);

    const configLoadHandler = useCallback(
        (event: Vsm.Event) => {
            const { target: map } = event as Vsm.MapEvent;
            const list = [];
            const fetchedConfig = map.getFetchedConfig();

            for (const [code, types] of Object.entries(
                map.getAvailableStyles()
            )) {
                for (const type of types) {
                    const { style } = findStyle(fetchedConfig, code, type);
                    list.push({ id: style, type });
                }
            }

            setAvailableStyles(list);
        },
        [setAvailableStyles]
    );

    return (
        <>
            <MapControlDock name={ControlDockNames.TopLeft}>
                <ReactBaseControl defaultInteractive>
                    <div className={classes.root}>
                        <div className={classes.top}>
                            <div className='vsm-contents-wrap'>
                                <div className='vsm-button-frame'>
                                    <button
                                        className={clsx(
                                            'vsm-button',
                                            classes.button
                                        )}
                                        onClick={() => setOpened(!opened)}
                                    >
                                        {!opened ? <OpenIcon /> : <CloseIcon />}
                                    </button>
                                </div>
                            </div>

                            {opened && <Typography>Style</Typography>}
                        </div>

                        <div className={classes.bottom}>
                            {opened && (
                                <RadioGroup value={value && toString(value)}>
                                    {availableStyles.map(style => {
                                        const value = toString(style);

                                        return (
                                            <FormControlLabel
                                                control={<Radio />}
                                                key={`style-${value}`}
                                                label={value}
                                                value={value}
                                                onChange={(event, checked) =>
                                                    checked && onChange(style)
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            )}
                        </div>
                    </div>
                </ReactBaseControl>
            </MapControlDock>

            <MapEventListener
                name={EventNames.ConfigLoad}
                listener={configLoadHandler}
            />
        </>
    );
};

export default MapStyleBox;
