import { getMap } from '@/apis/maps';
import DebugControl, { DEFAULT_DEBUG_OPTIONS } from '@/DebugControl';
import MapControls from '@/MapControls';
import MapEnlargeScaleFactor from '@/MapEnlargeScaleFactor';
import MapHistory from '@/MapHistory';
import MapLevel from '@/MapLevel';
import MapStyleBox from '@/MapStyleBox';
import { MapData } from '@/types/MapData';
import { findStyle, removeInvisibleStyle } from '@/utils/vsm';

import { Map, MapEventListener } from '@vsm/react-vsm';
import Vsm, { EnlargeScaleFactor, MapEvent } from '@vsm/vsm';
import produce, { Draft } from 'immer';
import { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { makeStyles } from 'tss-react/mui';

const { EventNames } = Vsm.Map;

function setDynamicManifest({ title, id }: MapData): void {
    const name = title || `Map ${id}`;
    const dynamicManifest = {
        short_name: name,
        name,
        icons: [
            {
                src: './icon.png',
                sizes: '192x192',
                type: 'image/png'
            }
        ],
        start_url: `.${window.location.search}`,
        display: 'standalone'
    };

    const blob = new Blob([JSON.stringify(dynamicManifest)], {
        type: 'application/json'
    });
    const placeholder = document.querySelector('#dynamic-manifest-placeholder');
    placeholder?.setAttribute('href', URL.createObjectURL(blob));
}

const useStyles = makeStyles()({
    root: {
        width: '100%',
        height: '100%'
    },
    map: {
        width: '100%',
        height: '100%'
    }
});

const history = new MapHistory();

const App = () => {
    const { classes } = useStyles();
    const params = history.getCurrentParams();
    const [fetchedConfig, setFetchedConfig] =
        useState<Vsm.InformalObject | null>(null);
    const [activatedMapStyle, setActivatedMapStyle] = useState(params.style);
    const [enlargeScaleFactor, setEnlargeScaleFactor] =
        useState<EnlargeScaleFactor>();

    const [debugOptions, setDebugOptions] = useState(
        params.debugOptions || DEFAULT_DEBUG_OPTIONS
    );

    useEffect(() => {
        window.scrollTo(0, 1);
    }, []);

    const { status, result } = useAsync(
        async () => getMap(params.mapId),
        [params.mapId]
    );

    useEffect(() => {
        if (status === 'success' && result) {
            setDynamicManifest(result);
        }
    }, [status, result]);

    const refinedConfig = useMemo(() => {
        if (
            !fetchedConfig ||
            status !== 'success' ||
            !result ||
            !result.config
        ) {
            return null;
        }

        const styleConfig = findStyle(
            fetchedConfig,
            activatedMapStyle?.id,
            activatedMapStyle?.type
        );
        const changed =
            !activatedMapStyle ||
            activatedMapStyle.id !== styleConfig.style ||
            activatedMapStyle.type !== styleConfig.type;
        const mapStyle = !changed
            ? activatedMapStyle
            : { id: styleConfig.style, type: styleConfig.type };

        if (changed) {
            setActivatedMapStyle(mapStyle);
        }

        return produce(fetchedConfig, (draft: Draft<any>) => {
            removeInvisibleStyle(findStyle(draft, mapStyle.id, mapStyle.type));
        });
    }, [status, result, fetchedConfig, activatedMapStyle]);

    switch (status) {
        case 'not-requested':
        case 'loading':
            return <div>Now loading...</div>;
        case 'error':
            return <div>Could not load map</div>;
    }

    return (
        <div className={classes.root}>
            <Map
                className={classes.map}
                id={params.mapId}
                config={refinedConfig || result?.config}
                defaultPosition={
                    params.cameraOptions ||
                    result?.position || {
                        center: [126.9849207, 37.5664519],
                        zoom: 17,
                        bearing: -36,
                        pitch: 50
                    }
                }
                activatedMapStyle={activatedMapStyle}
                enlargeScaleFactor={enlargeScaleFactor}
                disablePropDeepCompare
                defaultOptimize={{ noQuery: true }}
                preloadStyle={true}
                autoStyleLoad={Boolean(refinedConfig)}
            >
                <MapStyleBox
                    value={activatedMapStyle}
                    onChange={selected => setActivatedMapStyle(selected)}
                />

                <MapLevel />

                <MapControls />

                <MapEnlargeScaleFactor
                    value={enlargeScaleFactor}
                    onChange={scaleFactor => setEnlargeScaleFactor(scaleFactor)}
                />

                <DebugControl
                    options={debugOptions}
                    onChange={(newOptions, map) => {
                        setDebugOptions(newOptions);
                        history.push(map, newOptions);
                    }}
                />

                <MapEventListener
                    name={EventNames.ConfigLoad}
                    listener={event => {
                        const { target: map } = event as MapEvent;

                        if (!fetchedConfig) {
                            setFetchedConfig(map.getFetchedConfig());
                        }

                        history.listen(newParams => {
                            if (newParams.cameraOptions) {
                                map.getCamera().flyTo(
                                    newParams.cameraOptions,
                                    { speed: 2.5, maxDuration: 2000 },
                                    { byHistory: true, animate: true }
                                );
                            }

                            if (newParams.style) {
                                const currentStyle: any = map.getCurrentStyle();

                                if (
                                    currentStyle &&
                                    (currentStyle.style !==
                                        newParams.style.id ||
                                        currentStyle.type !==
                                            newParams.style.type)
                                ) {
                                    map.loadStyle(
                                        newParams.style.id,
                                        newParams.style.type
                                    );
                                }
                            }

                            setDebugOptions(
                                newParams.debugOptions || DEFAULT_DEBUG_OPTIONS
                            );
                        });
                    }}
                />

                <MapEventListener
                    name={EventNames.MoveEnd}
                    listener={event => {
                        const { target: map } = event as MapEvent;
                        !event.data.byHistory &&
                            history.push(map, debugOptions);
                    }}
                />

                <MapEventListener
                    name={EventNames.StyleLoad}
                    listener={event => {
                        const { target: map } = event as MapEvent;
                        history.push(map, debugOptions);
                    }}
                />
            </Map>
        </div>
    );
};

export default App;
