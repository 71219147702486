export const STYLE_KEY_DUMMY_VALUE = '0';

export default function formatStyleKeyString(
    layerId?: number | string,
    stackId?: number | string,
    stackStyleId?: number | string
): string {
    return `${layerId || STYLE_KEY_DUMMY_VALUE}/${
        stackId || STYLE_KEY_DUMMY_VALUE
    }/${stackStyleId || STYLE_KEY_DUMMY_VALUE}`;
}
