import { DebugOptions } from '@/DebugControl';
import { ActivatedMapStyle } from '@vsm/react-vsm';
import Vsm from '@vsm/vsm';

export type MapHistoryParams = {
    [name: string]: any;
    mapId: string;
    cameraOptions?: Vsm.CameraOptions;
    style?: ActivatedMapStyle;
    debugOptions?: DebugOptions;
};

class MapHistory {
    public push(map: Vsm.Map, options: DebugOptions): void {
        const idParam = MapHistory._createIdParam(map.getId());
        const cameraParam = MapHistory._createCameraParam(map.getCamera());
        const styleParam = MapHistory._createStyleParam(map.getCurrentStyle());
        const debugOptionsParam = MapHistory._createDebugOptionsParam(options);
        const params = [idParam, cameraParam, styleParam, debugOptionsParam];
        const path = `?${params.filter(param => param).join('&')}`;

        if (path !== window.location.search) {
            window.history.pushState({}, 'vsm-studio-preview', path);
        }
    }

    private static _createIdParam(mapId: string): string {
        return `mapId=${mapId}`;
    }

    private static _createCameraParam(camera: Vsm.MapCamera): string {
        const center = camera.getCenter();

        const essential =
            `camera=` +
            `/${camera.getZoom().toFixed(2)}` +
            `/${center.lng.toFixed(7)}` +
            `/${center.lat.toFixed(7)}`;

        let optional = '';

        if (camera.getPitch() !== 0.0 || camera.getBearing() !== 0.0) {
            optional =
                `/${camera.getBearing().toFixed(2)}` +
                `/${camera.getPitch().toFixed(2)}`;
        }

        return essential + optional;
    }

    private static _createStyleParam(styleConfig: any): string | null {
        if (!styleConfig) {
            return null;
        }

        const { style, type } = styleConfig;
        return `style=${style}.${type}`;
    }

    private static _createDebugOptionsParam(
        options: DebugOptions
    ): string | null {
        const entries = Object.entries(options).filter(([_, value]) => value);

        if (entries.length < 1) {
            return null;
        }

        const object = entries.reduce((acc: any, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

        return `debugOptions=${JSON.stringify(object)}`;
    }

    public listen(handler: (params: MapHistoryParams) => void): void {
        window.addEventListener('popstate', () => {
            handler(this.getCurrentParams());
        });
    }

    public getCurrentParams(): MapHistoryParams {
        const searchText = window.location.search;
        const params = searchText.slice(1).split(/[&]/);
        const result: any = {};

        for (const param of params) {
            const [key, value] = decodeURI(param).split(/=/);

            if (!value) {
                continue;
            }

            switch (key) {
                case 'mapId':
                    result.mapId = value;
                    break;

                case 'camera':
                    result.cameraOptions = MapHistory._parseCamera(value);
                    break;

                case 'style':
                    result.style = MapHistory._parseStyle(value);
                    break;

                case 'debugOptions':
                    result.debugOptions = MapHistory._parseDebugOptions(value);
                    break;

                default:
                    result[key] = value;
                    break;
            }
        }

        return result;
    }

    private static _parseCamera(paramValue: string): any {
        const tokens = paramValue.split('/');

        const parse = (str: string) => {
            const number = parseFloat(str);
            return !isNaN(number) ? number : null;
        };

        const parseCenter = (lngStr: string, latStr: string) => {
            const lng = parse(lngStr);
            const lat = parse(latStr);
            return lng !== null && lat !== null ? [lng, lat] : null;
        };

        return {
            zoom: parse(tokens[1]),
            center: parseCenter(tokens[2], tokens[3]),
            bearing: parse(tokens[4]),
            pitch: parse(tokens[5])
        };
    }

    private static _parseStyle(paramValue: string): ActivatedMapStyle {
        const tokens = paramValue.split('.');

        return {
            id: tokens[0],
            type: tokens[1]
        };
    }

    private static _parseDebugOptions(paramValue: string): DebugOptions {
        const options = JSON.parse(paramValue);

        return {
            tileDebugInfo: options.tileDebugInfo || false,
            collisionDetection: options.collisionDetection || false,
            poiVertex: options.poiVertex || false,
            network: options.network || false,
            forceBuildingInvisible: options.forceBuildingInvisible || false
        };
    }
}

export default MapHistory;
