import App from '@/App';
import '@/index.css';
import * as serviceWorker from '@/serviceWorker';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import '@vsm/vsm/dist/vsm.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

const theme = createTheme();
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>
);

serviceWorker.register();
